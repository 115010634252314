import React from 'react';

const Instructions = () => {
  return (
    <div className="container">
      <div className="header">
      <h2>Fund Load Restrictions Processing Challenge</h2>
      </div>
      <p>
        In the financial services industry, it's critical to manage the flow of funds into customer accounts while adhering to specific velocity limits and other controls. This challenge involves creating a program to adjudicate fund load attempts based on specified limits and restrictions.
      </p>
      <p>
        If you encounter any challenges or issues which require you to make assumptions about the data or challenge please document those assumptions as comments in your code.
      </p>
      <h3>Velocity Limits</h3>
      <table style={{width: '100%', borderCollapse: 'collapse', marginBottom: '20px'}}>
        <thead>
          <tr>
            <th style={{border: '1px solid #ddd', padding: '8px', textAlign: 'left'}}>Limit Type</th>
            <th style={{border: '1px solid #ddd', padding: '8px', textAlign: 'left'}}>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{border: '1px solid #ddd', padding: '8px'}}>Daily Limit</td>
            <td style={{border: '1px solid #ddd', padding: '8px'}}>A customer can load a maximum of $5,000 per day.</td>
          </tr>
          <tr>
            <td style={{border: '1px solid #ddd', padding: '8px'}}>Weekly Limit</td>
            <td style={{border: '1px solid #ddd', padding: '8px'}}>A customer can load a maximum of $20,000 per week.</td>
          </tr>
          <tr>
            <td style={{border: '1px solid #ddd', padding: '8px'}}>Daily Load Count</td>
            <td style={{border: '1px solid #ddd', padding: '8px'}}>A customer can perform a maximum of 3 load attempts per day, regardless of the amount.</td>
          </tr>
        </tbody>
      </table>
      <h3>Extra Credit - Special Sanctions (optional) </h3>
      <table style={{width: '100%', borderCollapse: 'collapse', marginBottom: '20px'}}>
        <thead>
          <tr>
            <th style={{border: '1px solid #ddd', padding: '8px', textAlign: 'left'}}>Sanction</th>
            <th style={{border: '1px solid #ddd', padding: '8px', textAlign: 'left'}}>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{border: '1px solid #ddd', padding: '8px'}}>Prime ID</td>
            <td style={{border: '1px solid #ddd', padding: '8px'}}>Due to new government regulations, any 'id' which is a prime number can only transact once per day with a maximum of $9,9999 per day.</td>
          </tr>
          <tr>
            <td style={{border: '1px solid #ddd', padding: '8px'}}>Mondays</td>
            <td style={{border: '1px solid #ddd', padding: '8px'}}>The regulator has determined that Mondays are not ideal and so any loads which occur on this day are counted as double their value.</td>
          </tr>
        </tbody>
      </table>
      <h3>Input</h3>
      <p>Fund load attempts will be provided in a single-line JSON format. The input data will be provided in a file named <code>input.txt</code>.</p>
      <h3>Output</h3>
      <p>For each fund load attempt, your program should output a JSON response indicating whether the load was accepted or declined based on the velocity limits.</p>
      <h3>Submission</h3>
      <div className="code-block">
          <pre>
        {`
        {"id":"15337","customer_id":"999","accepted":false}
        {"id":"34781","customer_id":"343","accepted":true}
        {"id":"26440","customer_id":"222","accepted":true}
        {"id":"12394","customer_id":"133","accepted":false}
        {"id":"15689","customer_id":"445","accepted":true}
        {"id":"32551","customer_id":"264","accepted":true}
        {"id":"27446","customer_id":"328","accepted":true}
        `}
          </pre>
        </div>

      <p>
        You may submit your output as <code>output.txt</code> along with your code.
      </p>
    </div>
  );
};

export default Instructions;
