import React, { useState } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';

import Download from './components/Download';
import Instructions from './components/Instructions';
import ThankYou from './components/ThankYou';
import Upload from './components/Upload';
import inviteCodes from './inviteCodes.json';

function InviteCodeInput({ onSubmit }) {
  const [inviteCode, setInviteCode] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(inviteCode);
  };

  const handleInviteCodeChange = (e) => {
    setInviteCode(e.target.value.toUpperCase());
  };

  return (
    <form onSubmit={handleSubmit} className="invite-code-form">
      <input
        type="text"
        value={inviteCode}
        onChange={handleInviteCodeChange}
        placeholder="Enter invite code"
        required
      />
      <button type="submit">Submit</button>
    </form>
  );
}

function App() {
  const [isInviteCodeValid, setIsInviteCodeValid] = useState(false);
  const [inviteCode, setInviteCode] = useState('');

  const handleInviteCodeSubmit = (code) => {
    const upperCaseCode = code.toUpperCase();
    if (inviteCodes.validCodes.includes(upperCaseCode)) {
      setIsInviteCodeValid(true);
      setInviteCode(upperCaseCode);
    } else {
      alert('Invalid invite code. Please try again.');
    }
  };

  return (
    <Router>
      <div className="app-container">
        {!isInviteCodeValid && (
          <div className="invite-code-container">
            <InviteCodeInput onSubmit={handleInviteCodeSubmit} />
          </div>
        )}
        <div className={`content-container ${isInviteCodeValid ? 'clear' : 'blurred'}`}>
          <header className="header">
            <h1>Technical Challenge</h1>
          </header>
          <main>
            <Routes>
              <Route path="/" element={
                <>
                  <Instructions />
                  <Download />
                  {isInviteCodeValid && <Upload inviteCode={inviteCode} />}
                </>
              } />
              <Route path="/thank-you" element={<ThankYou />} />
            </Routes>
          </main>
        </div>
      </div>
    </Router>
  );
}

export default App;
