import React from 'react';

const Download = () => {
  return (
    <div className="container">
          <section>
      <h2 className="section-title">Download Input Data</h2>
      <a href="/input.txt" className="download-button" download="input.txt">Download input.txt</a>
    </section>
    </div>
  );
};

export default Download;

