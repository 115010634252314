import { Link } from 'react-router-dom';
import React from 'react';

const ThankYou = () => {
  return (
    <div className="container">
        <section className="header">
      <h2>Thank you for your submission!</h2>
        </section>
      <p>Your files have been uploaded successfully. We will review your submission and get back to you shortly.</p>
      <Link to="/">Go back to the main page</Link>
    </div>
  );
};

export default ThankYou;
